<template>
    <div id="titlebar" :class=myclass>
      <div v-if="loggedIn">
        <div class="titlebar-l">
          <a v-if="showback" @click="goBack()"><i class="fas fa-angle-left"></i> </a>&nbsp;
        </div>
        <div class="titlebar-m">
           <div class="titlebar-col">
            <span v-if="icon" style="float:none;">
              <i :class="icon"></i> &nbsp;
            </span> 
            <span  style="float:none;">

            {{ mainTitle }}
            </span>
            </div>
        </div>
        <div class="titlebar-r">
          <div class="titlebar-button" style="float:right;">
            <a v-if="rbutton == 'newtrip'" @click="newTrip()"> <i class="fa fa-plus"></i></a>      
            <a v-if="rbutton == 'newcontact'" @click="newContact()"> <i class="fa fa-plus"></i></a>      
            <a v-if="rbutton == 'editcontact'" @click="editContact()"> <i class="fa fa-edit"></i></a>      
          </div>
        </div>
      </div>
      <div v-if="!loggedIn">
        <h1>TripTrader</h1> 
      </div>
    </div>
</template>

<script>

export default {
  name:'titlebar',
  props:['myclass','showback','rbutton',"icon","id"],
  methods: {
    newTrip() {
      this.$router.push('/trips/new');
    },
    newContact() {
      this.$router.push('/contacts/new');
    },
    editContact() {
      console.log("editContact: " + this.id);
      this.$router.push('/contacts/edit/' + this.id);
    },
     goBack() {
      return this.$router.go(-1);
    },
  },
  computed: {
    mainTitle() {
      return this.$route.name;
    },
  }
}
</script>