<template>
  <div class="register">
    <main-titlebar />




  
        <div class="grid-box-vscale">
          <p>
            <img id="profile-img" :src="logo" class="profile-img-card" alt="TripTrader Logo"/>
          </p>
        </div>

        <div v-if="!successful">
        <Form @submit="handleRegister" :validation-schema="schema">
          <p>Maak binnen enkele minuten uw account aan op TripTrader </p>
          <Field name="recaptchaValue" type="hidden" class="form-input" v-model="recaptchaValue" placeholder="recaptchaValue"/>

 

          <div class="trip-view-row">
            <div class="trip-view-col">
              <div class="form-group">     
                <Field v-model="email" name="email" type="text" class="form-input" placeholder="Uw email adres"/>
              </div>
            </div>
          </div>
          <div class="trip-view-row">
            <div class="form-group textAlignCenter" >          
              <ErrorMessage name="email" class="error-feedback" />
            </div>
          </div>
    

          <div class="trip-view-row">
            <div class="trip-view-col">
              <div class="form-group">     
                <Field name="password" type="text" class="form-input" placeholder="Uw wachtwoord"/>
              </div>
            </div>
          </div>
          <div class="trip-view-row">
            <div class="form-group textAlignCenter" >          
              <ErrorMessage name="password" class="error-feedback" />
            </div>
          </div>
    
      <p>&nbsp;</p>
       <div class="" style="text-align:center;display:inline-block;">
            <vue-recaptcha v-show="showRecaptcha" sitekey="6Lcw2RghAAAAAFbSx6CTsBboNYdhufABBGxTEti6"
            size="compact" 
            theme="light"
            hl="en"
            @verify="recaptchaVerified"
            @expire="recaptchaExpired"
            @fail="recaptchaFailed"
            ref="vueRecaptcha">
          </vue-recaptcha>
        </div>
        <div class="form-group">     
          <ErrorMessage name="recaptchaValue" class="error-feedback" />
        </div>

                <div class="bottomBar">
          <div class="col-2">
            <div class="bottomBar-button" @click="gotoLogin" :disabled="loading">Login</div>
          </div>
          <div class="col-2 alignRight">
          <button class="bottomBar-button" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Registeren
            </button>
          </div>
        </div>
         

      </Form>



        
  


      </div>

      



      <div class="form-group"> 
        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
      </div>

      <div class="form-group">&nbsp;</div>


      <div v-if="successful" class="trip-view-row">
        <div class="trip-view-col">
          <div class="register-button" @click="gotoLogin" :disabled="loading">Aanmelden</div>
        </div>
      </div>





  </div>
</template>
<script>
import vueRecaptcha from 'vue3-recaptcha2';
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "Register",
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    Form,
    Field,
    ErrorMessage,
    vueRecaptcha,
  },
  data() {
    const schema = yup.object().shape({
     
      email: yup
        .string()
        .required("Geen email adres opgegeven")
        .email("Ongeldig email adres")
        .max(50, "Email mag maximaal 50 karakters lang zijn"),
      password: yup
        .string()
        .required("Geen wachtwoord opgegeven")
        .min(6, "Wachtwoord moet minstens 6 karakters lang zijn")
        .max(40, "Wachtwoord mag maximaal 40 karakters lang zijn"),
      recaptchaValue: yup
        .string()
        .required("Bevestig dat u geen robot bent")
        .oneOf(["1"], "Bevestig dat u geen robot bent")
        ,
    });
    return {
      logo:require('../../assets/triptrader-logo.png'),
      successful: false,
      loading: false,
      message: "",
      schema,
      showRecaptcha: true,
      recaptchaValue: "0",
    };
  },
  computed: {

  },
  mounted() {
    if (this.loggedIn) {
     // this.$router.push("/");
    }
  },
  methods: {
    recaptchaVerified() {
      this.recaptchaValue = "1";
    },
    recaptchaExpired() {
      this.recaptchaValue = "0";

      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.recaptchaValue = "0";

    },
    gotoLogin() {
      this.$router.push('/login');
    },
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      // this.recaptchaValidated = true;
      var par = this;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          console.log("Account  email op " + par.email);
          if(data.status == "failed") {
            this.message = data.message;
            this.successful = false;
            this.loading = false;
          } else {
            this.message = "Account aangemaakt. U ontvangt een email op " + par.email;
            this.successful = true;
            this.loading = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>